import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import styled, { css } from 'styled-components'
import { colors } from '../style'

export const style = css`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? colors.green : colors.black)};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: ${({ dark }) => (dark ? colors.black : colors.white) };
    font-size: ${({ big }) => (big ? '20px': '16px')};
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2 ease-in-out;
        background: ${({ primary }) => (primary ? colors.white : colors.green)};
    }
`

export const Button = styled(LinkS)`
    ${style}
`

export const ButtonR = styled(LinkR)`
    ${style}
`