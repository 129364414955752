import collaborationImg from '../../images/collaboration.svg'
import aboutImg from '../../images/aboutUs1.svg'

export const aboutUs = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Notre mission',
    heading: 'Le Numérique Utile',
    description: 'Au quotidien, les équipes R&D de ABZYNE travaillent sur des projets innovants qui résolvent des problèmes concrets avec un réel impact sociétal.',
    imgStart: false,
    img: aboutImg,
    alt: 'Mission',
    dark: true,
    primary: true,
    darkText: false,
}

export const collaboration = {
    id: 'collaboration',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Envie d\'être accompagné ?',
    heading: 'Parlons de votre projet !',
    description: 'ABZYNE est spécialisé dans le conseil, la conception et le développement de solution numérique sur-mesure. Nous mettons un point d’honneur à proposer des produits uniques et de qualité, en offrant toujours à nos clients des particularités qui leurs permettent de se différencier sur le marché. Nous aurons toujours une oreille attentive à vos projets. N’hésitez pas à nous en faire part.',
    buttonLabel: 'Nous contacter',
    imgStart: false,
    img: collaborationImg,
    alt: 'Collaboration',
    dark: false,
    primary: false,
    darkText: true,
}