import React from 'react'
import { FaFacebook, FaLinkedin } from 'react-icons/fa'
import Logo from '../Navbar/Logo';
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterWrap,
  SocialIconLink,
  SocialIcons,
  SocialMedia,
  SocialMediaWrap,
  WebSiteRights,
} from './FooterElement';

function Footer() {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle> ABZYNE </FooterLinkTitle>
              <FooterLink to="/contact">Contact</FooterLink>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle> DJADO </FooterLinkTitle>
              <FooterLink to="/djado/faq">FAQ</FooterLink>
              <FooterLink to="/djado/terms">CGU</FooterLink>
              <FooterLink to="/djado/privacy">Confidentialité</FooterLink>
              <FooterLink to="/djado/eula">EULA</FooterLink>
              <FooterLink to="/djado/disclaimer">Disclaimer</FooterLink>
            </FooterLinkItems>

            {/* <FooterLinkItems>
              <FooterLinkTitle> Réseaux Sociaux </FooterLinkTitle>
              <FooterLink to="/about">Facebook</FooterLink>
              <FooterLink to="/about">Instagram</FooterLink>
              <FooterLink to="/about">Twitter</FooterLink>
            </FooterLinkItems> */}
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <Logo />
            <WebSiteRights>
              ABZYNE © { new Date().getFullYear() } All rights reserved
            </WebSiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.facebook.com/abzyne.sas' target='_blank' aria-label='Facebook'>
                  <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='https://www.linkedin.com/company/93100260' target='_blank' aria-label='Linkedin'>
                  <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer