module.exports = `
<h2>Politique de confidentialité</h2>
<p>Dernière mise à jour : 1er avril 2024</p>
<p>Bienvenue sur Djado !</p>

<p>
    La présente politique de confidentialité décrit nos politiques et procédures en matière de collecte, d'utilisation et de divulgation de Vos informations lorsque Vous utilisez le Service et Vous informe de Vos droits en matière de confidentialité et de la manière dont la loi Vous protège.
</p>
<p>
    Nous utilisons Vos données personnelles pour fournir et améliorer le service. En utilisant le Service, Vous acceptez la collecte et l'utilisation des informations conformément à la présente politique de confidentialité.
</p>

<p>
    La terminologie suivante s'applique à la présente politique de confidentialité, aux conditions générales, à l'avis de non-responsabilité et à tous les accords :
    <ul>
        <li>
            <i>Affilié</i> désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où "contrôle" signifie la propriété de 50% ou plus des actions, des participations ou d'autres titres ayant droit de vote pour l'élection des administrateurs ou d'autres autorités de gestion.
        </li>
        <li>
            <i>Application</i> désigne le programme logiciel fourni par la Société et téléchargé par Vous sur tout appareil électronique, nommé <i>Djado</i>
        </li>
        <li>
            <i>Client</i>, <i>Vous</i> et <i>Votre</i> se réfèrent à vous, la personne qui se connecte à ce site web et qui se conforme aux conditions générales de la société.
        </li>
        <li>
            <i>Appareil</i> désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.
        </li>
        <li>
            <i>Partie</i>, <i>Parties</i>, ou <i>Nous</i>, désigne à la fois le Client et nous-mêmes.
        </li>
        <li>
            <i>Les Données Personnelles</i> sont toutes les informations qui se rapportent à un individu identifié ou identifiable.
        </li>
        <li>
            <i>Service</i> désigne l'application
        </li>
        <li>
            <i>Fournisseur de services</i> désigne toute personne physique ou morale qui traite les données pour le compte de la société. Il s'agit de sociétés tierces ou de personnes employées par la société pour faciliter le service, pour fournir le Service au nom de la société, pour fournir des services liés au Service ou pour aider la société à analyser la façon dont le Service est utilisé.
        </li>
        <li><i>L'Entreprise</i>, <i>Nous-mêmes</i>, <i>Nous</i>, <i>Notre</i> et <i>Nous</i>, fait référence à notre entreprise, <i>ABZYNE</i>.</li>
        <li>
            <i>Les données d'utilisation</i> désignent les données collectées automatiquement, soit générées par l'utilisation du service, soit provenant de l'infrastructure du Service elle-même (par exemple, la durée de la visite d'une page).
        </li>
    </ul>
    Tous les termes se réfèrent à l'offre, à l'acceptation et à la considération du paiement nécessaire pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée dans le but exprès de répondre aux besoins du Client en ce qui concerne la fourniture des services déclarés de la Société, conformément à et sous réserve de la loi en vigueur au Niger. Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, avec une majuscule et/ou il/elle ou ils/elles, est considérée comme interchangeable et par conséquent comme se référant à la même chose.
</p>

<h2>Collecte et utilisation de Vos données personnelles</h2>
<h3>Types de données collectées</h3>
<h4>Données personnelles</h4>
<p>
    Lorsque Vous utilisez notre service, nous pouvons Vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour Vous contacter ou Vous identifier. Les informations personnelles identifiables peuvent inclure, mais ne sont pas limitées à :

<ul>
    <li>
        <p>Adresse électronique</p> <p>
    </li>
    <li>
        <p>Numéro de téléphone</p> <p>
    </li>
    <li>
        <p>Données d'utilisation</p>
    </li>
</ul>
<h4>Données d'utilisation</h4>
<p> Les données d'utilisation sont collectées automatiquement lors de l'utilisation du Service. </p>
<p>
    Les données d'utilisation peuvent inclure des informations telles que l'adresse IP de Votre appareil, le type de navigateur, la version du navigateur, les pages de notre Service que Vous visitez, l'heure et la date de Votre visite, le temps passé sur ces pages, les identifiants uniques de l'appareil et d'autres données de diagnostic.
</p>
<p>
    Lorsque Vous accédez au Service au moyen d'un appareil mobile, nous pouvons collecter automatiquement certaines informations, notamment le type d'appareil mobile que Vous utilisez, l'identifiant unique de Votre appareil mobile, l'adresse IP de Votre appareil mobile, Votre système d'exploitation mobile, le type de navigateur Internet mobile que Vous utilisez, les identifiants uniques de l'appareil et d'autres données de diagnostic.
</p>
<p>
    Nous pouvons également collecter des informations que Votre navigateur envoie chaque fois que Vous visitez notre Service ou que Vous accédez au Service par ou via un appareil mobile.
</p>

<h3>Utilisation de Vos données à caractère personnel</h3>
<p>L'entreprise peut utiliser les données personnelles aux fins suivantes :</p>
<ul>
    <li>
        </p><strong>Pour fournir et maintenir notre Service</strong>, y compris pour surveiller l'utilisation de notre Service.</p>

    <li>
        <p>
            <strong>Pour gérer Votre compte :</strong> pour gérer Votre enregistrement en tant qu'utilisateur du service. Les données personnelles que Vous fournissez peuvent Vous donner accès à différentes fonctionnalités du Service qui sont disponibles pour Vous en tant qu'utilisateur enregistré.
        </p>
    </li>
    <li>
        <p>
            <strong>Pour l'exécution d'un contrat:</strong> le développement, la conformité et l'entreprise du contrat d'achat pour les produits, articles ou services que Vous avez achetés ou de tout autre contrat avec Nous par le biais du Service.
        </p>
    </li>
    <li>
        <p>
            <strong>Pour Vous contacter :</strong> Pour Vous contacter par email, appels téléphoniques, SMS, ou autres formes équivalentes de communication électronique, telles que les notifications push d'une application mobile concernant les mises à jour ou les communications informatives liées aux fonctionnalités, produits ou services contractés, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.
        </p>
    </li>
    <li>
        <p>
            <strong>Pour Vous fournir</strong> des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons et qui sont similaires à ceux que Vous avez déjà achetés ou sur lesquels Vous Vous êtes renseigné, à moins que Vous n'ayez choisi de ne pas recevoir de telles informations.
        </p>
    </li>
    <li>
        </p>
            <strong>Pour gérer Vos demandes :</strong> Pour répondre à Vos demandes et les gérer.
        </p>
    </li>
    <li>
        <p>
            <strong>Pour les transferts d'entreprise :</strong> Nous pouvons utiliser Vos informations pour évaluer ou mener à bien une fusion, une cession, une restructuration, une réorganisation, une dissolution ou toute autre vente ou transfert d'une partie ou de la totalité de nos actifs, que ce soit en tant qu'entreprise en activité ou dans le cadre d'une faillite, d'une liquidation ou d'une procédure similaire, dans laquelle les données personnelles que nous détenons sur les utilisateurs de nos services font partie des actifs transférés.
        </p>
    </li>
    <li>
        <p>
            <strong>À d'autres fins</strong> : Nous pouvons utiliser Vos informations à d'autres fins, telles que l'analyse des données, l'identification des tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et l'évaluation et l'amélioration de notre service, de nos produits, de nos services, de notre marketing et de Votre expérience.
        </p>
    </li>
</ul>

</p> <p>Nous pouvons partager Vos informations personnelles dans les situations suivantes:</p>
<ul>
    <li>
        <strong>Avec les fournisseurs de services :</strong> Nous pouvons partager Vos informations personnelles avec les fournisseurs de services pour contrôler et analyser l'utilisation de notre service, pour Vous contacter.
    </li>
    <li>
        <strong>Pour les transferts d'entreprise :</strong> Nous pouvons partager ou transférer Vos informations personnelles dans le cadre d'une fusion, d'une vente d'actifs de la société, d'un financement ou d'une acquisition de tout ou partie de nos activités à une autre société, ou pendant les négociations relatives à ces opérations.
    </li>
    <li>
        <strong>Avec les Affiliés :</strong> Nous pouvons partager Vos informations avec nos sociétés affiliées, auquel cas nous demanderons à ces sociétés affiliées de respecter la présente politique de confidentialité. Les sociétés affiliées comprennent notre société mère et toutes les autres filiales, partenaires de coentreprise ou autres sociétés que nous contrôlons ou qui sont sous contrôle commun avec nous.
    </li>
    <li>
        <strong>Avec des partenaires commerciaux :</strong> Nous pouvons partager Vos informations avec nos partenaires commerciaux afin de Vous proposer certains produits, services ou promotions.
    </li>
    <li>
        <strong>Avec d'autres utilisateurs :</strong> lorsque Vous partagez des informations personnelles ou interagissez d'une autre manière avec d'autres utilisateurs dans les zones publiques, ces informations peuvent être consultées par tous les utilisateurs et peuvent être diffusées publiquement à l'extérieur.
    </li>
    <li>
        <strong>Avec Votre consentement :</strong>  Nous pouvons divulguer Vos informations personnelles à toute autre fin avec Votre consentement.
    </li>
</ul>
<h3>Conservation de Vos données à caractère personnel</h3>
<p>
    La Société ne conservera Vos données personnelles que pendant la durée nécessaire aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et utiliserons Vos données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver Vos données pour nous conformer aux lois applicables), résoudre les litiges et appliquer nos accords et politiques juridiques.
</p>
<p>
    La Société conservera également les données d'utilisation à des fins d'analyse interne. Les données d'utilisation sont généralement conservées pendant une période plus courte, sauf si ces données sont utilisées pour renforcer la sécurité ou améliorer la fonctionnalité de notre service, ou si nous sommes légalement tenus de conserver ces données pendant des périodes plus longues.
</p>
<h3>Transfert de Vos données personnelles</h3>
<p>
    Vos informations, y compris les données personnelles, sont traitées dans les bureaux de la Société et dans tout autre lieu où se trouvent les parties impliquées dans le traitement. Cela signifie que ces informations peuvent être transférées vers - et conservées sur - des ordinateurs situés en dehors de Votre État, province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de Votre juridiction.
</p>
<p>
    Votre consentement à la présente politique de protection de la vie privée, suivi de Votre soumission de ces informations, représente Votre accord à ce transfert.
</p>
<p>
    La Société prendra toutes les mesures raisonnablement nécessaires pour s'assurer que Vos données sont traitées en toute sécurité et conformément à la présente politique de confidentialité et aucun transfert de Vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il n'y ait des contrôles adéquats en place, y compris la sécurité de Vos données et d'autres informations personnelles.
</p>

<h3>Divulgation de Vos données à caractère personnel</h3>
<h4>Transactions commerciales</h4>
<p>
    Si l'entreprise est impliquée dans une fusion, une acquisition ou une vente d'actifs, Vos données personnelles peuvent être transférées. Nous Vous préviendrons avant que Vos données personnelles ne soient transférées et soumises à une politique de confidentialité différente.
</p>

<h4>Application de la loi</h4>
<p>
    Dans certaines circonstances, l'entreprise peut être tenue de divulguer Vos données personnelles si la loi l'exige ou en réponse à des demandes valables émanant d'autorités publiques (par exemple, un tribunal ou une agence gouvernementale).
</p>
<h4>Autres exigences légales</h4>
<L'entreprise peut divulguer Vos données personnelles si elle estime en toute bonne foi qu'une telle action est nécessaire pour
<ul>
    <li>Se conformer à une obligation légale</li>
    <li>Protéger et défendre les droits ou la propriété de l'entreprise</li>
    <li>Prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec le Service</li>
    <li>Protéger la sécurité personnelle des utilisateurs du Service ou du public</li>
    <li>Protéger contre la responsabilité légale</li>
</ul>
<h3>Sécurité de Vos données personnelles</h3>
<p>
    La sécurité de Vos données personnelles est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est sûre à 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger Vos données personnelles, nous ne pouvons pas garantir leur sécurité absolue.
</p>
<h2>Liens vers d'autres sites web</h2>
<p>
    Notre Service peut contenir des liens vers d'autres sites Internet qui ne sont pas exploités par Nous. Si Vous cliquez sur le lien d'un tiers, Vous serez dirigé vers le site de ce tiers. Nous Vous conseillons vivement de consulter la politique de confidentialité de chaque site que Vous visitez.
</p>
<p>
    Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques des sites ou services tiers.
</p>
<h2>Modifications de la présente politique de confidentialité</h2>
<p>
    Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous Vous informerons de toute modification en publiant la nouvelle politique de confidentialité sur cette page.
</p>
<p>
    Nous Vous en informerons par courrier électronique et/ou par un avis bien visible sur notre service, avant que la modification n'entre en vigueur, et nous mettrons à jour la date de la dernière mise à jour en haut de la présente politique de protection de la vie privée.
<p>
    Nous Vous conseillons de consulter régulièrement la présente politique de confidentialité pour prendre connaissance des éventuelles modifications. Les modifications apportées à la présente politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page.
</p>
<h2>Contactez-nous</h2>
</p> <p>Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter:</p>
<ul>
    <li>
        en visitant cette page de notre site web : <a href="https://www.abzyne.com/contact" rel="external nofollow noopener" target="_blank">https://www.abzyne.com/contact</a>
    </li>
</ul>
`