import React, { useState, useEffect } from 'react'
import Question from './Question'
import { 
    FaqTitle, 
    FaqContainer, 
    Welcome, 
    Answer,
    AnswerList,
    AnswerListElement,
    ContactLink,
} from './FaqElement'
import { 
    Record, 
    RecordCircle,
    ArrowCircleUp,
    Triangle,
    Component,
    Diamonds,
    Crown,
    Crown1,
} from 'iconsax-react'
import Footer from '../../Footer'

const Faq = () => {
    const [activeQuestionId, setActiveQuestionId] = useState()

    useEffect(() => {
        const id = window.location.hash.replace('#', '')
        if (id) {
            setActiveQuestionId(id)
            const element = document.getElementById(id)
            element && element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start'
            })
        }
    }, [])

    return (
        <>
            <FaqContainer>
                <FaqTitle>
                    Foire aux questions
                </FaqTitle>
                <Welcome> Bienvenue sur Djado </Welcome>

                <Question
                    questionId='1'
                    activeQuestionId={activeQuestionId}
                    setActiveQuestionId={setActiveQuestionId}
                    content='Qu’est-ce que c’est Djado ?'
                >
                    <Answer>
                        Djado est une application mobile, tout public, qui célèbre la Culture Nigérienne 🇳🇪 et Africaine 🌍.
                        Djado est de loin la meilleure manière d'en apprendre sur le Niger tout en s'amusant.
                        Ce projet a été d’abord motivé par le désir de promouvoir la richesse et l’étendue de la culture Nigérienne.
                    </Answer>
                    <Answer>
                        Le nom «Djado» a été inspiré par l’illustre historien et conteur Djado Sékou. Mais aussi par le Plateau du Djado (massif au Nord-Est du Niger).
                    </Answer>
                </Question>

                <Question
                    questionId='2'
                    activeQuestionId={activeQuestionId}
                    setActiveQuestionId={setActiveQuestionId}
                    content='Quels modes de jeu existent-il sur Djado ?'
                >
                    <AnswerList>
                        Il existe 3 modes de jeux :
                        <AnswerListElement>
                            Question du jour: Une question au quotidien 
                        </AnswerListElement>
                        <AnswerListElement>
                            Ligue: Ce mode de jeu en ligne permet de vous mesurer aux autres joueurs de la plate-forme en match classé 1 vs 1. 
                            Il existe un classement des meilleurs joueurs par saison.
                        </AnswerListElement>
                        <AnswerListElement>
                            Tournoi: Les joueurs inscrits prennent part à la compétition à une heure précise, répondent aux mêmes questions et sont classés par ordre de mérite. 
                            A la clé des récompenses diverses. 
                        </AnswerListElement>
                        D’autres modes de jeu sont à venir (Training, Sans Faute et Entre Amis). Restez câbler 😉
                    </AnswerList>
                    <Answer>
                        Il y a 2 types de quiz. Les anagrammes et les QCM.
                    </Answer>
                </Question>

                <Question
                    questionId='3'
                    activeQuestionId={activeQuestionId}
                    setActiveQuestionId={setActiveQuestionId}
                    content='Quels sont les éléments de gain du jeu ?'
                >
                    <Answer>
                        Chaque quiz donne droit à un certain nombre de points d’expériences (XP) selon le niveau de difficulté de la question. 
                        Le classement XP permet de classer les joueurs par expérience.
                    </Answer>
                    <Answer>
                        Le joueur peut avoir droit à des pièces d’Or suite à une bonne réponse à la question du jour, une victoire en ligue, une participation à un tournoi, un parrainage, etc… 
                        Les pièces d’or permettent au joueur d’acheter des équipements (et bien d’autres choses à venir)
                    </Answer>
                </Question>

                <Question
                    questionId='4'
                    activeQuestionId={activeQuestionId}
                    setActiveQuestionId={setActiveQuestionId}
                    content='Quels sont les différents niveaux d’avancement ?'
                >
                    <AnswerList>
                        Il existe 8 niveaux, échelonnés en fonction du nombre d’XP
                        <AnswerListElement>
                            0 - 99 XP: <b>Djeli</b>  <Record size={16} /> : Griot en langues Mandingues
                        </AnswerListElement>
                        <AnswerListElement>
                            100 - 499 XP: <b>Gewel</b> <RecordCircle size={16} /> : Griot en Wolof
                        </AnswerListElement>
                        <AnswerListElement>
                            500 - 999 XP: <b>Djassar</b> <ArrowCircleUp size={16} /> Griot en Zarma, Gesere en Soninke
                        </AnswerListElement>
                        <AnswerListElement>
                            1000 - 1999 XP: <b>Bambadô</b> <Triangle size={16} />  Griot en Peul
                        </AnswerListElement>
                        <AnswerListElement>
                            2000 - 4999 XP: <b>Kumatigi</b> <Component size={16} /> «maître de la parole» en langues Mandingues
                        </AnswerListElement>
                        <AnswerListElement>
                            5000 - 9999 XP: <b>Korrow</b> <Diamonds size={16} /> «le plus vieux griot» en langues Mandingues
                        </AnswerListElement>
                        <AnswerListElement>
                            10000 - 29999 XP: <b>Kuntigi</b> <Crown size={16} /> «chef des griots» en langues Mandingues
                        </AnswerListElement>
                        <AnswerListElement>
                            À partir de 30000 XP: <b>Sarkin Tarihi</b> <Crown1 size={16} /> «roi du récit» en Haoussa
                        </AnswerListElement>
                    </AnswerList>
                </Question>

                <Question
                    questionId='5'
                    activeQuestionId={activeQuestionId}
                    setActiveQuestionId={setActiveQuestionId}
                    content='Concours et autres compétitions, quelles sont les règles ?'
                >
                    <Answer>
                        Sur Djado, les joueurs ont la possibilité de remporter des lots divers (espèce ou nature) en participant à des tournois par exemple.
                        Ces récompenses sont accordées aux joueurs les mieux classés sur lesdits tournois.
                        Une récompense similaire pourrait-être mise en place pour les autres modes de jeux disponibles sur l'application.
                    </Answer>
                    <Answer>
                        Pour chaque compétition (tournoi, ligue, etc...) ABZYNE se réserve le droit de décerner ou non des prix (lots). Dans tous les cas, cette décision sera explicitée et connue de tous les participants bien avant le début de la compétition.
                        ABZYNE se réserve le droit de disqualifier tout joueur qui se rend coupable de fraude ou autres actes de nature à saboter le bon déroulement de la compétition ou à perturber le fonctionnement de l'application.
                        ABZYNE peut soumettre la remise du prix à certaines conditions. L'entreprise se réserve le droit d'imposer un mode de paiement (virement bancaire, mobile money, etc..) ou de livraison.
                    </Answer>
                     <Answer>
                        Toutes les compétitions organisées sur Djado sont sponsorisées par ABZYNE (Karim SAYABOU EI), le développeur de l'application. 
                        ABZYNE est responsable de l'organisation, de la gestion et de la distribution des prix. Les prix peuvent être fournis par nos partenaires et sponsors, mais ceux-ci n'ont aucune responsabilité dans l'organisation ou la gestion des concours. Ces partenaires peuvent être mentionnés à titre indicatif.
                    </Answer>
                    <br/>
                    <Answer>
                        Apple n'est ni sponsor ni impliqué dans nos différentes compétitions.
                    </Answer>
                </Question>

                <Question
                    questionId='6'
                    activeQuestionId={activeQuestionId}
                    setActiveQuestionId={setActiveQuestionId}
                    content='Comment contribuer au contenu de Djado ?'
                >
                    <Answer>
                        Vous pouvez nous envoyer vos quiz, vos faits historiques ou culturels (Les Faits de Sékou) et toutes autres propositions de rubrique ou de fonctionnalité.
                    </Answer>
                    <Answer>
                        N'hésitez pas à nous <ContactLink to='/contact'> Contacter ❤️</ContactLink> 
                    </Answer>
                </Question>

                <Question
                    questionId='7'
                    activeQuestionId={activeQuestionId}
                    setActiveQuestionId={setActiveQuestionId}
                    content='Professionnel, comment être partenaire, sponsor ou promouvoir mon entreprise ?'
                >
                    <Answer>
                        N'hésitez pas à nous <ContactLink to='/contact'> Contacter ❤️</ContactLink> 
                    </Answer>
                </Question>

            </FaqContainer>

            <Footer />
        </>
    )
}

export default Faq