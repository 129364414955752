import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../../style'

export const FooterContainer = styled.footer`
    background: ${colors.black};
`

export const FooterWrap = styled.div`
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;
    @media screen and (max-with: 820px) {
        padding-top: 32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;
    @media screen and (max-with: 820px) {
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: ${colors.white};

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
`

export const FooterLink = styled(Link)`
    color: ${colors.white};
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: ${colors.green};
        transition: 0.3 ease-in-out;
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
` 

export const WebSiteRights = styled.small`
    text-align: center;
    color: ${colors.white};
    margin-bottom: 16px;
`

export const SocialIcons = styled.div`
    display: flex;
    align-items: center;
    width: 140px;
`

export const SocialIconLink = styled.a`
    color: ${colors.white};
    font-size: 24px;
    margin-right: 24px;
`
