import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../../../style'

export const FaqContainer = styled.div`
    width: 100%;
    padding: 1rem;
    margin-bottom: 10rem;
`

export const FaqTitle = styled.h1`
    font-size: 30px;
    text-align: center;
    margin: 2rem auto;
    color: ${colors.black};

    @media screen and (max-width: 768px) {
        font-size: 26px;
    }
`

export const Welcome = styled.p`
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
    color: ${colors.black};
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`

export const QuestionContainer = styled.div`
    width: 85%;
    max-width: 900px;
    margin: 1rem auto;
    padding: 8px 12px;
    border-radius: 4px;
    scroll-margin: 1.5rem;
    border: 0.5px solid ${colors.grey};
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 4px 6px;
    }
`

export const Question = styled.a`
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: ${({ highlighted }) => (highlighted ? colors.green : colors.black )};
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`

export const answerStyle = css`
    font-size: 16px;
    margin-left: 4px;
    color: ${colors.grey};
    @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-left: 2px;
    }
`

export const Answer = styled.p`
    ${answerStyle}
`

export const AnswerList = styled.ul`
    ${answerStyle}
`

export const AnswerListElement = styled.li`
    ${answerStyle}
    margin-left: 4%;
    list-style: inside;
    list-style-position: outside;
    @media screen and (max-width: 768px) {
        margin-left: 8%;
    }
`

export const IconContainer = styled.div`
    top: 0%;
    float: right;
    cursor: pointer;
    padding-left: 1rem;
`

export const ContactLink = styled(Link)`
    color: ${colors.black};
    font-weight: bold;
    text-decoration: none;

    &:hover {
        color: ${colors.green};
        transition: 0.3 ease-in-out;
    }
`