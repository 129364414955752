module.exports = `
<h2>Contrat de licence pour l'utilisateur final (CLUF / EULA)</h2>
<p>Dernière mise à jour : 1er avril 2024</p>
<p>Bienvenue sur Djado !</p>

<p>Le présent contrat de licence pour utilisateur final ("CLUF") est un accord juridique entre vous et Djado</p>

<p>
    Cet accord CLUF régit l'utilisation de notre application Djado ("App") directement auprès de Djado ou indirectement par l'intermédiaire d'un revendeur ou distributeur agréé par Djado (un "Revendeur").
</p>

<p>
    Veuillez lire attentivement cet accord CLUF avant de terminer le processus d'installation et d'utiliser l'application Djado. Il fournit une licence d'utilisation de l'application Djado et contient des informations sur la garantie et des clauses de non-responsabilité.
</p>

<p>
    Si vous vous inscrivez pour un essai gratuit de l'application Djado, cet accord CLUF régira également cet essai. En cliquant sur "accepter" ou en installant et/ou en utilisant l'application Djado, vous confirmez votre acceptation de l'application et acceptez d'être lié par les termes de cet accord CLUF.
</p>

<p>
    Si vous concluez cet accord CLUF au nom d'une société ou d'une autre entité juridique, vous déclarez que vous avez le pouvoir de lier cette entité et ses affiliés à ces termes et conditions. Si vous n'avez pas cette autorité ou si vous n'êtes pas d'accord avec les termes et conditions de cet accord CLUF, n'installez pas ou n'utilisez pas l'application, et vous ne devez pas accepter cet accord CLUF.    
</p>

<p>
    Cet accord CLUF s'applique uniquement à l'application fournie par Djado, indépendamment du fait que d'autres logiciels soient mentionnés ou décrits dans le présent document. Les conditions s'appliquent également à toutes les mises à jour, à tous les suppléments, à tous les services Internet et à tous les services d'assistance de Djado pour l'application, à moins que d'autres conditions n'accompagnent ces éléments lors de la livraison. Dans ce cas, ces conditions s'appliquent.
</p>

<h3>Octroi de licence</h3>

<p>
    Djado vous accorde par la présente une licence personnelle, non transférable et non exclusive pour utiliser l'application Djado sur vos appareils conformément aux termes de cet accord CLUF.    
</p>

<p>
    Vous êtes autorisé à charger l'application Djado (par exemple un PC, un ordinateur portable, un mobile ou une tablette) sous votre contrôle. Vous êtes responsable de vous assurer que votre appareil répond aux exigences minimales de l'application Djado.
</p>

<p>Vous n'êtes pas autorisé à :</p>

<ul>
    <li>
        Éditer, modifier, adapter, traduire ou changer de toute autre manière l'ensemble ou une partie de l'application, ni permettre que l'ensemble ou une partie de l'application soit combinée ou incorporée dans un autre logiciel, ni décompiler, désassembler ou désosser l'application, ni tenter de le faire.
    </li>
    <li>
        Reproduire, copier, distribuer, revendre ou utiliser de toute autre manière l'application à des fins commerciales.
    </li>
    <li>
        Permettre à un tiers d'utiliser l'application au nom ou au bénéfice d'un tiers
    </li>
    <li>
        Utiliser l'application d'une manière qui enfreint toute loi locale, nationale ou internationale applicable.
    </li>
    <li>
        Utiliser l'application à des fins que Djado considère comme une violation du présent accord CLUF
    </li>
</ul>

<h3>Propriété intellectuelle et droit de propriété</h3>
<p>
    Djado restera à tout moment propriétaire de l'application telle qu'elle a été téléchargée à l'origine par vous et de tous les téléchargements ultérieurs de l'application par vous. L'application (ainsi que les droits d'auteur et autres droits de propriété intellectuelle de quelque nature que ce soit sur l'application, y compris toute modification apportée à celle-ci) est et restera la propriété de Djado.
</p>
<p>
    Djado se réserve le droit d'accorder des licences d'utilisation de l'Application à des tiers.
</p>

<h3>La loi applicable</h3>
<p>
    Le présent accord CLUF, ainsi que tout litige découlant du présent accord CLUF ou en rapport avec celui-ci, sont régis et interprétés conformément au droit nigérien.
</p>

</div>
`