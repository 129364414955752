module.exports = `
<h2>Termes et conditions</h2>
<p>Dernière mise à jour : 1er avril 2024</p>
<p>Bienvenue sur Djado !</p>

<p>Ces termes et conditions décrivent les règles et règlements pour l'utilisation de Djado.</p> 
<p>
    En utilisant cette application, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser Djado si vous n'acceptez pas tous les termes et conditions énoncés sur cette page.
</p>

<ul>
    La terminologie suivante s'applique aux présentes conditions générales, à la déclaration de confidentialité, à l'avis de non-responsabilité et à tous les accords : 
    <li><i>Affilié</i> désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où "contrôle" signifie la propriété de 50% ou plus des actions, des participations ou d'autres titres ayant droit de vote pour l'élection des administrateurs ou d'autres autorités de gestion.</li>
    <li><i>Application</i> désigne le programme logiciel fourni par la Société et téléchargé par Vous sur tout appareil électronique, nommé <i>Djado</i></li>
    <li><i>Client</i>, <i>Vous</i> et <i>Votre</i> se réfèrent à vous, la personne qui se connecte à ce site web et qui se conforme aux conditions générales de la société.</li>
    <li><i>Appareil</i> désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.</li>
    <li> <i>Partie</i>, <i>Parties</i>, ou <i>Nous</i>, désigne à la fois le Client et nous-mêmes.</li>
    <li><i>Les Données Personnelles</i> sont toutes les informations qui se rapportent à un individu identifié ou identifiable.</li>
    <li><i>Service</i> désigne l'application</li>
    <li>
        <i>Fournisseur de services</i> désigne toute personne physique ou morale qui traite les données pour le compte de la Société. Il s'agit de sociétés tierces ou de personnes employées par la Société pour faciliter le Service, pour fournir le Service au nom de la Société, pour effectuer des services liés au Service ou pour aider la Société à analyser la façon dont le Service est utilisé.
    </li>
    <li>
        <i>L'entreprise</i>, <i>Nous-mêmes</i>, <i>Nous</i>, <i>Notre</i> et <i>Nous</i>, fait référence à notre entreprise, <i>ABZYNE</i>
    </li>
    <li>
       <i>Les Données d'utilisation</i> désignent les données collectées automatiquement, soit générées par l'utilisation du Service, soit provenant de l'infrastructure du Service elle-même (par exemple, la durée de la visite d'une page).
    </li>
</ul>
<p>
    Tous les termes se réfèrent à l'offre, à l'acceptation et à la considération du paiement nécessaire pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée dans le but exprès de répondre aux besoins du Client en ce qui concerne la fourniture des services déclarés de la Société, conformément à et sous réserve de la loi en vigueur au Niger.  Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, avec une majuscule et/ou il/elle ou ils/elles, est considérée comme interchangeable et par conséquent comme se référant à la même chose.
</p>

<h3><strong>Récompenses - Prix</strong></h3>
<p>
    Sur Djado, les joueurs ont la possibilité de remporter des lots divers (espèce ou nature) en participant à des tournois par exemple.
    Ces récompenses sont accordées aux joueurs les mieux classés sur lesdits tournois.
    Une récompense similaire pourrait-être mise en place pour les autres modes de jeux disponibles sur l'application.
</p>
<p>
    Pour chaque compétition (tournoi, ligue, etc...) ABZYNE se réserve le droit de décerner ou non des prix (lots). Dans tous les cas, cette décision sera explicitée et connue de tous les participants bien avant le début de la compétition.
    ABZYNE se réserve le droit de disqualifier tout joueur qui se rend coupable de fraude ou autres actes de nature à saboter le bon déroulement de la compétition ou à perturber le fonctionnement de l'application.
    ABZYNE peut soumettre la remise du prix à certaines conditions. L'entreprise se réserve le droit d'imposer un mode de paiement (virement bancaire, mobile money, etc..) ou de livraison.
</p>
<p>
    Toutes les compétitions organisées sur Djado sont sponsorisées par ABZYNE (Karim SAYABOU EI), le développeur de l'application. 
    ABZYNE est responsable de l'organisation, de la gestion et de la distribution des prix. Les prix peuvent être fournis par nos partenaires et sponsors, mais ceux-ci n'ont aucune responsabilité dans l'organisation ou la gestion des concours. Ces partenaires peuvent être mentionnés à titre indicatif.
</p>
<p>
    Apple n'est ni sponsor ni impliqué dans nos différentes compétitions.
</p>
<h3><strong>Licence</strong></h3>
<p>Sauf indication contraire, Djado et/ou ses concédants de licence détiennent les droits de propriété intellectuelle pour l'ensemble du contenu de Djado. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez accéder au contenu de Djado pour votre usage personnel, sous réserve des restrictions énoncées dans les présentes conditions d'utilisation.
<ul>
Vous ne devez pas
    <li>Republier le matériel de Djado</li>
    <li>Vendre, louer ou concéder en sous-licence des éléments de Djado</li>
    <li>Reproduire, dupliquer ou copier le contenu de Djado</li>
    <li>Redistribuer le contenu de Djado</li>
</ul>

<p>Le présent Accord prend effet à la date du présent document.</p>

<p>
    Certaines parties de cette application pourraient offrir aux utilisateurs la possibilité de publier et d'échanger des opinions et des informations dans certaines zones du site Web. Djado ne filtre, ne modifie, ne publie ni ne révise les commentaires avant leur présence sur l'Application. Les commentaires ne reflètent pas les points de vue et opinions de Djado, de ses agents et/ou affiliés. Les commentaires reflètent les points de vue et les opinions de la personne qui publie ses points de vue et ses opinions. Dans la mesure permise par les lois applicables, Djado ne sera pas responsable des Commentaires ni de toute responsabilité, dommage ou dépense causé et/ou subi à la suite de toute utilisation et/ou publication et/ou apparition des Commentaires sur l'Application.
</p>

<p>
    Djado se réserve le droit de contrôler tous les commentaires et de supprimer tout commentaire pouvant être considéré comme inapproprié, offensant ou constituant une violation des présentes conditions générales.
</p>

<ul>
    Vous garantissez et déclarez que :
    <li>Vous avez le droit de publier les Commentaires sur notre Application et disposez de toutes les licences et consentements nécessaires pour le faire ;</li>
    <li>Les Commentaires ne portent atteinte à aucun droit de propriété intellectuelle, y compris, sans limitation, les droits d'auteur, les brevets ou les marques déposées d'un tiers ;</li>
    <li>Les commentaires ne contiennent pas de matériel diffamatoire, offensant, indécent ou autrement illégal qui constitue une atteinte à la vie privée.</li>
    <li>Les commentaires ne seront pas utilisés pour solliciter ou promouvoir des affaires ou des coutumes ou pour présenter des activités commerciales ou des activités illégales.</li>
</ul>
<p>
    Par la présente, vous accordez à Djado une licence non exclusive pour utiliser, reproduire, éditer et autoriser d'autres personnes à utiliser, reproduire et éditer vos commentaires sous toutes les formes, formats ou médias.
</p>

<h3><strong>L'hyperlien vers notre application</strong></h3>
<ul>
    Les organisations suivantes peuvent établir un lien vers notre application sans autorisation écrite préalable :
    <li>Agences gouvernementales;</li>
    <li>Moteurs de recherche;</li>
    <li>Organismes de presse;</li>
    <li>Les distributeurs d'annuaires en ligne peuvent établir un lien avec notre application de la même manière qu'ils établissent un lien hypertexte avec les sites Web d'autres entreprises répertoriées.</li>
    <li>Les entreprises accréditées à l'échelle du système, à l'exception des organisations à but non lucratif, des centres commerciaux caritatifs et des groupes de collecte de fonds caritatifs, qui ne peuvent pas établir de lien hypertexte avec notre site Web.</li>
</ul>
<p>
    Ces organisations peuvent établir un lien vers notre page d'accueil, vers des publications ou vers d'autres informations relatives à l'App, à condition que le lien : (a) n'est en aucun cas trompeur ; (b) n'implique pas faussement le parrainage, l'approbation ou le soutien de la partie qui établit le lien et de ses produits et/ou services ; et (c) s'inscrit dans le contexte du site de la partie qui établit le lien.
</p>

<ul>
    Nous pouvons prendre en considération et approuver d'autres demandes de liens émanant des types d'organisations suivants :
    <li>sources d'information sur les consommateurs et/ou les entreprises connues de tous;</li>
    <li>les sites communautaires de type .com ; </li>
    <li>associations ou autres groupes représentant des œuvres de bienfaisance ; </li>
    <li>distributeurs d'annuaires en ligne ; </li>
    <li>portails Internet ; </li>
    <li>les cabinets d'experts-comptables, d'avocats et de consultants ;</li>
    <li>les institutions éducatives et associations commerciales.</li>
</ul>

<p>
    Nous approuverons les demandes de liens émanant de ces organisations si nous décidons que : (a) le lien ne donnera pas une image défavorable de nous-mêmes ou de nos entreprises accréditées ; (b) l'organisation n'a pas d'antécédents négatifs à notre égard ; (c) l'avantage que nous tirons de la visibilité du lien hypertexte compense l'absence de Djado ; et (d) le lien s'inscrit dans le contexte d'une information générale sur les ressources.
</p>
<p>
    Ces organisations peuvent établir un lien avec notre application à condition que le lien : (a) n'est en aucun cas trompeur ; (b) n'implique pas faussement le parrainage, l'endossement ou l'approbation de la partie qui établit le lien et de ses produits ou services ; et (c) s'inscrit dans le contexte du site de la partie qui établit le lien.
</p>
<p>
    Si vous êtes l'une des organisations énumérées au paragraphe 2 ci-dessus et que vous souhaitez créer un lien vers notre application, vous devez nous en informer en envoyant un e-mail à Djado. Veuillez inclure votre nom, le nom de votre organisation, vos coordonnées ainsi que l'URL de votre site, une liste de toutes les URL à partir desquelles vous avez l'intention de créer un lien vers notre application, et une liste des URL de notre site vers lesquelles vous aimeriez créer un lien. Attendez 2 à 3 semaines pour obtenir une réponse.
</p>
<ul>
    Les organisations agréées peuvent créer un lien hypertexte vers notre application de la manière suivante :
    <li>En utilisant notre raison sociale ; ou</li>
    <li>En utilisant le localisateur de ressources uniformes vers lequel le lien est établi ; ou</li>
    <li>Par l'utilisation de toute autre description de notre application qui a un sens dans le contexte et le format du contenu sur le site de la partie qui établit le lien.</li>
</ul>

<p>
    Aucune utilisation du logo de Djado ou d'autres illustrations ne sera autorisée pour l'établissement de liens en l'absence d'un accord de licence de marque.
</p>
<h3><strong>iFrames</strong></h3>
<p>
    Sans accord préalable et autorisation écrite, vous ne pouvez pas créer de cadres autour de nos pages Web qui modifient de quelque manière que ce soit la présentation visuelle ou l'apparence de notre application.
</p>
<h3><strong>Responsabilité du contenu</strong></h3>
<p>
    Nous ne serons pas tenus responsables du contenu qui apparaît sur votre application. Vous acceptez de nous protéger et de nous défendre contre toute réclamation concernant le contenu de votre application. Aucun lien ne doit apparaître sur un site web qui pourrait être interprété comme diffamatoire, obscène ou criminel, ou qui enfreint, viole ou préconise l'enfreinte ou la violation des droits d'un tiers.
</p>
<h3><strong>Votre confidentialité</strong></h3>
<p>
    Veuillez lire la politique de confidentialité sur cette page <a href="https://www.abzyne.com/djado/privacy" rel="external nofollow noopener" target="_blank">https://www.abzyne.com/djado/privacy</a>
</p>
<h3><strong>Réservation des droits</strong></h3>
<p>
    Nous nous réservons le droit de vous demander de supprimer tous les liens ou un lien particulier vers notre application. Vous acceptez de supprimer immédiatement tous les liens vers notre application sur demande. Nous nous réservons également le droit de modifier à tout moment les présentes conditions et la politique de liens. En continuant à créer des liens vers notre application, vous acceptez d'être lié à ces conditions générales et de les respecter.
</p>

<h3><strong>Suppression des liens de notre application</strong></h3>
<p>
    Si vous trouvez un lien sur notre application qui est offensant pour quelque raison que ce soit, vous êtes libre de nous contacter et de nous en informer à tout moment. Nous examinerons les demandes de suppression de liens, mais nous ne sommes pas obligés de le faire ou de vous répondre directement.
</p>
<p>
    Nous n'assurons pas que les informations contenues sur ce site web sont correctes, nous ne garantissons pas leur exhaustivité ou leur exactitude ; nous ne promettons pas non plus d'assurer que le site web reste disponible ou que le matériel contenu sur le site web soit maintenu à jour.
</p>
<h3><strong>Disclaimer</strong></h3>

<ul>
    Dans toute la mesure permise par la loi applicable, nous excluons toutes les représentations, garanties et conditions relatives à notre application et à l'utilisation de ce site web. Rien dans cette clause de non-responsabilité ne peut :
    <li>limiter ou exclure notre ou votre responsabilité en cas de décès ou de dommages corporels;</li>
    <li>limiter ou exclure notre ou votre responsabilité en cas de fraude ou de déclaration frauduleuse ; </li>
    <li>limiter notre ou vos responsabilités d'une manière qui n'est pas autorisée par la loi applicable ; ou</li>
    <li>exclure l'une quelconque de nos ou de vos responsabilités qui ne peuvent être exclues en vertu de la loi applicable.</li>
</ul>
<p>
    Les limitations et interdictions de responsabilité énoncées dans la présente section et ailleurs dans la présente clause de non-responsabilité : (a) sont soumises au paragraphe précédent ; et (b) régissent toutes les responsabilités découlant de la clause de non-responsabilité, y compris les responsabilités contractuelles, délictuelles et en cas de violation d'une obligation légale.
</p>

<p>
    Tant que le site web et les informations et services qu'il contient sont fournis gratuitement, nous ne sommes pas responsables des pertes ou dommages de quelque nature que ce soit.
</p>

`