import { 
  MobileIcon, 
  Nav, 
  NavbarContainer, 
  NavItem, 
  NavMenu, 
  NavLink, 
  NavBtn, 
  NavBntLink 
} from './NavbarElement'
import React, { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import Logo from './Logo'

const linkProps = {
  smooth: true,
  duration: 500,
  spy: true,
  exact: 'true',
  offset: -80,
}

export const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  function changeNav() {
    setScrollNav(window.scrollY >= 80)
  }

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <Logo />
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
                <NavLink 
                  to='about'
                  {...linkProps}
                >
                  Notre Mission
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink 
                  to='djado' 
                  {...linkProps}
                >
                  Djado
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink 
                  to='services' 
                  {...linkProps}
                >
                  Nos Prestations
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink 
                  to='collaboration' 
                  {...linkProps}
                >
                  Vos Projets
                </NavLink>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBntLink to='/contact'>Nous contacter</NavBntLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  )
}
