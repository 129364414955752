import { FaCheckCircle, FaPaperPlane } from 'react-icons/fa'
import { Field, Form as FForm, ErrorMessage } from 'formik'
import styled from 'styled-components'
import { colors } from '../../style'

export const Container = styled.div`
    overflow: scroll;
    //width: 100%;
    min-height: 100vh;
    background: ${colors.white};
`

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const LogoContainer = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    padding-left: 6rem;
    background-color: ${colors.black};

    @media screen and (max-width: 767px) {
        padding: 0;
        justify-content: center;
    }
`

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    margin: 16px 0 64px 0;

    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px) {
        padding: 10px;
    }
`

export const Form = styled(FForm)`
    width: 90%;
    height: auto;
    z-index: 1;
    display: grid;
    max-width: 600px;
    margin: 16px auto;
    padding: 32px 32px;
    border-radius: 8px;
    border-left: 2px ${colors.black} solid;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        width: 95%;
        padding: 32px 16px;
    }
`

export const FormH1 = styled.h1`
    color: ${colors.black};
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 16px;
`

export const FormH2 = styled.h2`
    color: ${colors.black};
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
`

export const FormElementContainer = styled.div`
    display: flex;
    width: 47.5%;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

export const FormMultiInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`

export const Error = styled(ErrorMessage)`
    font-size: 14px;
    color: ${colors.red};
`

export const FormInput = styled(Field)`
    border: none;
    background: ${colors.lightGrey};
    font-size: 16px;
    max-width: 100%;
    border-radius: 2px;
    padding: 12px 16px;
    margin-top: 16px;
    outline-color: ${colors.black};
    @media screen and (max-width: 300px) {
        width: 100%;
    }
`

export const FormButton = styled.button`
    border-radius: 50px;
    background: ${colors.black};
    white-space: nowrap;
    padding: 12px 30px;
    color: ${colors.white};
    font-size: 16px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 16px 25% 0 25%;
`

export const SendIcon = styled(FaPaperPlane)`
    margin-left: 0.5rem;
`

export const SentIcon = styled(FaCheckCircle)`
    margin-left: 0.5rem;
`

export const FormTextArea = styled(Field)`
    border: none;
    resize: none;
    font-size: 16px;
    padding: 16px 16px;
    border-radius: 4px;
    background: ${colors.lightGrey};
    margin: 16px 0 0 0;
    outline-color: ${colors.black};
`

export const Result = styled.span`
    font-size: 16px;
    margin: 16px 0 0 0;
    text-align: center;
    color: ${colors.black};
`