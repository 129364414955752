import * as Yup from 'yup'

export const initialValues = {
    name: '',
    email: '',
    company: '',
    phone: '',
    subject: '',
    message: '',
}

export const schema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Nom trop court')
        .required('Requis'),
    email: Yup.string()
        .email('Email invalide')
        .required('Requis'),
    subject: Yup.string()
        .min(2, 'Sujet trop court')
        .required('Requis'),
    message: Yup.string()
        .min(10, 'Message trop court')
        .required('Requis'),
})
