import './App.css'
import { DjadoEula, DjadoTerms, DjadoPrivacy, DjadoDisclaimer } from './pages/DjadoLegals'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import DjadoFaq from './pages/DjadoFaq'
import Contact from './pages/Contact'
import Home from './pages'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} exact /> 
        <Route path='/contact' element={<Contact />} exact /> 
        <Route path='/djado/faq' element={<DjadoFaq />} exact /> 
        <Route path='/djado/eula' element={<DjadoEula />} exact /> 
        <Route path='/djado/terms' element={<DjadoTerms />} exact /> 
        <Route path='/djado/privacy' element={<DjadoPrivacy />} exact /> 
        <Route path='/djado/disclaimer' element={<DjadoDisclaimer />} exact /> 
      </Routes>
    </Router>
  )
}

export default App