import React from 'react'
import { HeroBg, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg } from './HeroSectionElement'
import Video from '../../videos/video.mp4'
import Typed from 'react-typed'

const HeroSection = () => {
  return (
    <HeroContainer id='home'>
        <HeroBg>
            <VideoBg
                autoPlay
                loop muted
                playsInline
                src={Video} 
                type='video/mp4'
            />
        </HeroBg>
        <HeroContent>
            <HeroH1>
                ABZYNE
            </HeroH1>
            <HeroH1>
                <Typed
                    strings={['pour l’éducation ', 'pour la culture ', 'pour le développement ', 'pour une meilleure société ', 'pour VOUS ! ']}
                    typeSpeed={65}
                    backSpeed={30}
                    loop
                />
            </HeroH1>
            <HeroP>
                Nous sommes engagés à travers nos projets et ceux de nos clients à participer au développement économique et social des communautés locales. 
            </HeroP>
        </HeroContent>

    </HeroContainer>
  )
}

export default HeroSection