import './Legal.css'
import { TextContainer } from './LegalElement.js'
import eula from '../../../static/eula.html'
import terms from '../../../static/terms.html'
import privacy from '../../../static/privacy.html'
import disclaimer from '../../../static/disclaimer.html'

import Footer from '../../Footer'
import React from 'react'

export const Eula = () => {
  return (
    <>
        <TextContainer dangerouslySetInnerHTML={{ __html: eula }}/>
        <Footer />
    </>
  )
}

export const Terms = () => {
  return (
    <>
        <TextContainer dangerouslySetInnerHTML={{ __html: terms }}/>
        <Footer />
    </>
  )
}

export const Privacy = () => {
  return (
    <>
        <TextContainer dangerouslySetInnerHTML={{ __html: privacy }}/>
        <Footer />
    </>
  )
}

export const Disclaimer = () => {
  return (
    <>
        <TextContainer dangerouslySetInnerHTML={{ __html: disclaimer }}/>
        <Footer />
    </>
  )
}