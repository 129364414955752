import ScrollToTop from '../components/ScrollToTop'
import Contact from '../components/Contact'
import React from 'react'

function ContactPage() {
  return (
    <>
      <ScrollToTop />
      <Contact />
    </>
  )
}

export default ContactPage