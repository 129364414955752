import { ButtonR } from '../ButtonElement'
import React from 'react'
import { 
  BtnWrap, 
  Column1, 
  Column2, 
  Heading, 
  Img, 
  ImgWrap, 
  InfoContainer, 
  InfoRow, 
  InfoWrapper, 
  TextWrapper, 
  TopLine, 
  Subtitle
} from './InfoElement'

const InfoSection = (props) => {
  function displayContact() {
    if (!props.buttonLabel) {
      return
    }

    return (
      <BtnWrap>
        <ButtonR to='contact'>
          { props.buttonLabel }
        </ButtonR>
      </BtnWrap>
    )
  }

  return (
    <>
      <InfoContainer id={props.id} lightBg={props.lightBg}>
        <InfoWrapper>
          <InfoRow imgStart={props.imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>
                  { props.topLine }
                </TopLine>
                <Heading lightText={props.lightText}>
                  { props.heading }
                </Heading>
                <Subtitle darkText={props.darkText}>
                  { props.description }
                </Subtitle>
                { displayContact() }
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={props.img} alt={props.alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection