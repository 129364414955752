import React, { useState } from 'react'
import { colors } from '../../style'
import Logo from '../Navbar/Logo'
import { Formik } from 'formik'
import Footer from '../Footer'
import axios from 'axios'
import {
    Container,
    Form,
    FormButton, 
    FormContent, 
    FormElementContainer, 
    FormH1, 
    FormH2, 
    FormInput, 
    FormMultiInputContainer, 
    FormTextArea, 
    FormWrap,
    Error,
    LogoContainer,
    SendIcon,
    SentIcon,
    Result,
} from './ContactElement'
import { initialValues, schema } from './FormValidation'

// STATUS
const FAIL      = 'fail'
const LOADING   = 'loading'
const SUCCESS   = 'success'

const Contact = () => {
    const [status, setStatus] = useState('')

    function btnText() {
        if (status === SUCCESS) {
            return (
                <>
                    Message envoyé <SentIcon color={colors.green} />
                </>
            )
        }

        if (status === LOADING) {
            return (
                <>
                    Envoie encours...
                </>
            )
        }

        if (status === FAIL) {
            return (
                <>
                    Echec de l'envoi 😕
                </>
            )
        }

        return (
            <>
                Envoyer <SendIcon />
            </>
        )
    }

    function dispatchConfirmation() {
        if (status === SUCCESS) {
            return (
                <Result>
                    Merci de nous avoir contacté
                </Result>
            )
        }

        if (status === FAIL) {
            return (
                <Result>
                    Veuillez réessayer plus tard
                </Result>
            )
        }
    }

    async function onSubmit(values, actions) {
        setStatus(LOADING)
        try {
            await axios.post(
                'https://europe-west3-abzyne-b2b24.cloudfunctions.net/contact',
                values,
            )
            setStatus(SUCCESS)
        } catch(error) {
            console.log(error)
            setStatus(FAIL)
        }

        actions.resetForm()

        setTimeout(() => setStatus(''), 5000)
    }

    return (
        <>
            <Container>
                <FormWrap>
                    <LogoContainer>
                        <Logo />
                    </LogoContainer>
                    <FormContent>
                        <Formik 
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={onSubmit}
                        >
                            <Form action='#'>
                                <FormH1>
                                    Contactez-nous !
                                </FormH1>
                                <FormH2>
                                    Nos équipes vous répondront dans les plus brefs délais !
                                </FormH2>

                                <FormMultiInputContainer>
                                    <FormElementContainer>
                                        <FormInput 
                                            name='name' 
                                            placeholder='Nom'
                                        />
                                        <Error name='name' component='div' />
                                    </FormElementContainer>
                                    <FormElementContainer>
                                        <FormInput 
                                            name='company' 
                                            placeholder='Entreprise'
                                        />
                                    </FormElementContainer>
                                </FormMultiInputContainer>

                                <FormMultiInputContainer>
                                    <FormElementContainer>
                                        <FormInput
                                            name='email' 
                                            type='email'
                                            placeholder='Email'
                                        />
                                        <Error name='email' component='div' />
                                    </FormElementContainer>
                                    <FormElementContainer>
                                        <FormInput
                                            name='phone' 
                                            type='tel'
                                            placeholder='N° Téléphone'
                                        />
                                    </FormElementContainer>
                                </FormMultiInputContainer>
                    
                                <FormInput 
                                    name='subject' 
                                    placeholder='Sujet'
                                />
                                <Error name='subject' component='div' />

                                <FormTextArea
                                    rows={5}
                                    name='message' 
                                    component='textarea'
                                    placeholder='Message'
                                />
                                <Error name='message' component='div' />
                                
                                <FormButton 
                                    type='submit'
                                    disabled={status !== ''}
                                >
                                    { btnText() }
                                </FormButton>
                                
                                { dispatchConfirmation() }
                            </Form>
                        </Formik>
                    </FormContent>
                </FormWrap>
            </Container>
            <Footer />
        </>
    )
}

export default Contact