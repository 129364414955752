import { 
  BtnWrap, 
  Column1, 
  Column2, 
  Heading, 
  Img, 
  ImgWrap, 
  InfoContainer, 
  InfoRow, 
  InfoWrapper, 
  TextWrapper, 
  TopLine, 
  Subtitle,
  AppStoreIcon,
  GooglePlayIcon,
  StoreLink
} from './InfoElement'
import djadoImg from '../../images/djado.png'
import React from 'react'

const Djado = () => {
  return (
    <>
      <InfoContainer id={'djado'} lightBg={true}>
        <InfoWrapper>
          <InfoRow imgStart={true}>
            <Column1>
              <TextWrapper>
                <TopLine>
                    #PourLaCulture
                </TopLine>
                <Heading lightText={false}>
                  DJADO
                </Heading>
                <Subtitle darkText={true}>
                  DJADO est une application mobile, tout public, qui célèbre la Culture Nigérienne 🇳🇪 et Africaine 🌍. 
                  Tous les domaines de la culture y sont abordés : sport, cinéma, art, musique, histoire, littérature, politique, sciences… Rejoignez-nous dans l'aventure DJADO 🚀
                </Subtitle>
                <BtnWrap>
                  <StoreLink href='https://apps.apple.com/us/app/djado/id6502918814' target='_blank' aria-label='GooglePlay'>
                    Télécharger sur <AppStoreIcon size={22} />
                  </StoreLink>
                  <StoreLink href='https://play.google.com/store/apps/details?id=com.abzyne.djado' target='_blank' aria-label='GooglePlay'>
                    Télécharger sur <GooglePlayIcon size={20} />
                  </StoreLink>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={djadoImg} alt='djado' />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Djado