import { aboutUs, collaboration } from '../components/InfoSection/Data'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import Djado from '../components/InfoSection/Djado'
import { Sidebar } from '../components/Sidebar'
import { Navbar } from '../components/Navbar'
import Services from '../components/Services'
import Footer from '../components/Footer'
import React, { useState } from 'react'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar 
        isOpen={isOpen}
        toggle={toggle}
      />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...aboutUs} />
      <Djado />
      <Services />
      <InfoSection {...collaboration} />
      <Footer />
    </>
  )
}

export default Home