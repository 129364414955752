module.exports = `
<h2>Clause de non-responsabilité</h2>
<p>Dernière mise à jour : 1er avril 2024</p>
<p>Bienvenue sur Djado !</p>

<p>
    Nous faisons de notre mieux pour préparer le contenu de cette application. 
    Toutefois, Djado ne peut garantir les expressions et suggestions du contenu, 
    ainsi que son exactitude. En outre, dans la mesure permise par la loi, 
    Djado ne sera pas responsable des pertes et/ou dommages dus à 
    l'utilisation de l'information contenue dans notre application.
</p>

<p>En utilisant notre application, vous consentez par la présente à notre clause de non-responsabilité et en acceptez les termes.</p>

<p>
    Tous les liens contenus dans notre application peuvent mener à des sites externes et ne sont fournis que pour des raisons de commodité. Toute information ou
    déclarations apparaissant sur ces sites ou dans l'application ne sont pas sponsorisées, endossées ou autrement approuvées par Djado. 
    Pour ces sites externes, Djado ne peut être tenu responsable de la disponibilité ou du contenu de ces sites ou de l'application.
    De plus, toute perte ou dommage survenu lors de l'utilisation de ces contenus ou de l'internet en général.
</p>

<div style="margin: 10rem 0;" />
`