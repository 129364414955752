import { 
    ServicesCard, 
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesIcon,
    ServicesP,
    ServicesWrapper
} from './ServicesElement'
import mobile from '../../images/mobile.svg'
import software from '../../images/software.svg'
import loyalty from '../../images/loyalty.svg'
import React from 'react'

const Services = () => {
  return (
    <ServicesContainer id='services'>
        <ServicesH1>
             Nos Prestations
        </ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                 <ServicesIcon src={software}/>
                 <ServicesH2>
                    Logiciel
                 </ServicesH2>
                <ServicesP>
                    Application Desktop, Application Web, Backend, Architechture logicielle, Bases de données ...
                </ServicesP>
            </ServicesCard>
            <ServicesCard>
                 <ServicesIcon src={mobile}/>
                 <ServicesH2>
                    Application mobile
                 </ServicesH2>
                <ServicesP>
                    Maquette, AB Testing, Design UX/UI, Développement cross-platform (Android & IOS) ...
                </ServicesP>
            </ServicesCard>
            <ServicesCard>
                 <ServicesIcon src={loyalty}/>
                 <ServicesH2>
                    Système de fidélisation
                 </ServicesH2>
                <ServicesP>
                    Exploitation de données utilisateurs, Acquisition et Rétention d'utilisateurs, Dashboard & KPIs ...
                </ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services