import ScrollToTop from '../components/ScrollToTop'
import Faq from '../components/Djado/Faq'
import React from 'react'

export const DjadoFaq = () => {
  return (
    <>
      <ScrollToTop />
      <Faq />
    </>
  )
}


export default DjadoFaq