import { FaMinus, FaPlus } from 'react-icons/fa'
import { colors } from '../../../style'
import React from 'react'
import {
    Question as QLine,
    IconContainer,
    QuestionContainer 
} from './FaqElement'

const Question = (props) => {
    const { activeQuestionId, questionId  } = props
 
    function isActive() {
        return activeQuestionId === questionId
    }

    function expandIcon() {
        if (isActive()) {
            return (
                <FaMinus 
                    size={20} 
                    color={colors.green}
                    onClick={() => props.setActiveQuestionId(undefined)}
                />
            )
        }

        return (
            <FaPlus 
                size={20} 
                color={colors.black} 
                onClick={() => props.setActiveQuestionId(questionId)}
            />
        )
    }

    function answer() {
        if (!isActive()) {
            return
        }
        return props.children
    }
    
    return (
        <QuestionContainer id={questionId}>
            <IconContainer>
                { expandIcon() }
            </IconContainer>
            <QLine 
                href={`#${questionId}`}
                highlighted={isActive()}
                onClick={() => props.setActiveQuestionId(isActive() ? undefined : questionId)}
            >
                { props.content }
            </QLine>
            { answer() }
        </QuestionContainer>
  )
}

export default Question