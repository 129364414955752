import { animateScroll as scroll } from 'react-scroll'
import { LogoImg, NavLogo } from './NavbarElement'
import logo from '../../images/abzyne.png'

export const Logo = () => {
    function toggleHome() {
        scroll.scrollToTop()
      }

    return (
        <NavLogo
            to='/'
            onClick={toggleHome}
        >
            ABZYNE
            <LogoImg src={logo} alt='Abzyne' />
      </NavLogo>
    )
}

export default Logo