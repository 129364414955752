import { Icon, CloseIcon, SidebarContainer, SidebarLink, SidebarMenu, SidebarRoute, SidebarWrapper, SideBtnWrap } from './SidebarElement'
import React from 'react'
 
 export const Sidebar = ({ isOpen, toggle }) => {
   return (
     <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to='about' onClick={toggle}>Notre Mission</SidebarLink>
                <SidebarLink to='djado' onClick={toggle}>Djado</SidebarLink>
                <SidebarLink to='services' onClick={toggle}>Nos Prestations</SidebarLink>
                <SidebarLink to='collaboration' onClick={toggle}>Vos Projets</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to='contact'>Nous Contacter</SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
     </SidebarContainer>
   )
 }
