import { Eula, Terms, Privacy, Disclaimer } from '../components/Djado/Legals'
import ScrollToTop from '../components/ScrollToTop'
import React from 'react'

export const DjadoEula = () => {
  return (
    <>
      <ScrollToTop />
      <Eula />
    </>
  )
}

export const DjadoTerms = () => {
  return (
    <>
      <ScrollToTop />
      <Terms />
    </>
  )
}

export const DjadoPrivacy = () => {
  return (
    <>
      <ScrollToTop />
      <Privacy />
    </>
  )
}

export const DjadoDisclaimer = () => {
  return (
    <>
      <ScrollToTop />
      <Disclaimer />
    </>
  )
}
